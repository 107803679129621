/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

jQuery(".survey .answer:last").bind("inview", function (event, visible) {
  var $self = jQuery(this);
  if (visible) {
    jQuery(".survey .bar-fill").each(function () {
      var w = jQuery(this).css("width");
      jQuery(this).css("width", 0);
      jQuery(this).animate(
        {
          width: w,
        },
        1000
      );
      $self.unbind("inview");
    });
  }
});

(function ($) {
  //initialize form map picker
  var googleFormMap = function () {
    var initializeMap = $("#form-map-init");
    if (initializeMap.length > 0) {
      initializeMap.append('<div id="form-map"></div>');

      var g = google; //eslint-disable-line no-undef

      var markerDefaultLat = 50.11532;
      var markerDefaultLng = 14.61242;

      var map = new g.maps.Map(document.getElementById("form-map"), {
        zoom: 14,
        center: new g.maps.LatLng(markerDefaultLat, markerDefaultLng),
        mapTypeId: g.maps.MapTypeId.ROADMAP,
      });

      var myMarker = new g.maps.Marker({
        position: new g.maps.LatLng(markerDefaultLat, markerDefaultLng),
        draggable: true,
      });

      var timeout;
      g.maps.event.addListener(myMarker, "dragend", function (evt) {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(function () {
          var lat = evt.latLng.lat().toFixed(4);
          var lng = evt.latLng.lng().toFixed(4);

          $("#location").val(lat + "-" + lng);
        }, 150);
      });

      map.setCenter(myMarker.position);
      myMarker.setMap(map);

      $(window).trigger("resize");
    }
  };

  if (window.gmapsLoaded) {
    googleFormMap();
  } else {
    $(document).on("gmapsLoaded", googleFormMap);
  }
})(jQuery);

(function ($) {
  $(
    ".sub-nav .menu-item-has-children:not(.current-menu-item, .current-menu-ancestor) > a"
  ).on("click", function (e) {
    e.preventDefault();
    var element = $(this).parent("li");
    if (element.hasClass("active")) {
      element.removeClass("active");
      element.find("ul").slideUp(200);

      element
        .siblings("li:not(.current-menu-item, .current-menu-ancestor)")
        .removeClass("active");
      element
        .find("li:not(.current-menu-item, .current-menu-ancestor)")
        .removeClass("active");
    } else {
      element.addClass("active");
      element.children("ul").slideDown(200);

      element
        .siblings("li:not(.current-menu-item, .current-menu-ancestor)")
        .removeClass("active")
        .find("li:not(.current-menu-item, .current-menu-ancestor)")
        .removeClass("active")
        .find("ul")
        .slideUp(200);
    }
  });
})(jQuery);

(function ($) {
  $alert = $(".alert:visible:first:not(.alert-info)");
  if ($alert.length) {
    setTimeout(function () {
      $("html, body").animate(
        {
          scrollTop:
            $alert.parent().offset().top -
            parseInt($("html").css("margin-top")),
        },
        1000
      );
    }, 1000);
  }
})(jQuery);

(function ($) {
  $searchForm = $(".search-form");
  $searchIcon = $(".search-form i");

  $searchIcon.click(function () {
    $searchForm.submit();
  });
})(jQuery);

(function ($) {
  $(document).ready(function () {
    var datePicker = $(".gform_body .datepicker");
    if (datePicker.length > 0) {
      datePicker.datepicker({ format: "dd/mm/yyyy" });
    }
  });
})(jQuery);
