(function ($) {
  function getViewportHeight() {
    var height = window.innerHeight; // Safari, Opera
    var mode = document.compatMode;

    if (mode || !$.support.boxModel) {
      // IE, Gecko
      height =
        mode === "CSS1Compat"
          ? document.documentElement.clientHeight // Standards
          : document.body.clientHeight; // Quirks
    }

    return height;
  }

  $(window).scroll(function () {
    var viewportHeight = getViewportHeight(),
      scrolltop = document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop,
      elements = [];

    $.each($.cache, function () {
      if (this.events && this.events.inview) {
        elements.push(this.handle.elem);
      }
    });

    if (elements.length) {
      $(elements).each(function () {
        var $self = $(this),
          top = $self.offset().top,
          height = $self.height(),
          inview = $self.data("inview") || false;

        if (scrolltop > top + height || scrolltop + viewportHeight < top) {
          if (inview) {
            $self.data("inview", false);
            $self.trigger("inview", [false]);
          }
        } else if (scrolltop < top + height) {
          if (!inview) {
            $self.data("inview", true);
            $self.trigger("inview", [true]);
          }
        }
      });
    }
  });

  $(function () {
    $(window).scroll();
  });
})(jQuery);
